@use "sass:map";

footer {
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0px 0px 10px;
  font-family: map.get($theme-fonts, "primary-font");
  color: $color-shark;
  text-align: center;
  color: var(--chat-box-text-color) !important;
}
