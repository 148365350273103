.hide {
  margin-left: -280px;
}

.h-100vh {
  height: 100vh;
}

.dropdown-toggle {
  width: 100%;
  margin-block: 5px;
}

