.reset-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1e90ff;
    height: 100vh;
}

.reset-password-container {
    background-color: #1e2a3c;
    padding: 40px 20px;
    max-width: 500px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}