.checkbox {
    opacity: 0;
    position: absolute;
  }
  
  .checkbox-label {
    background-color: var(--theme-toggler-dark);
    width: 50px;
    height: 26px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .checkbox-label .icon {
    color: var(--theme-toggler-light);
  }
  
  .checkbox-label .ball {
    background-color: var(--theme-toggler-light);
    width: 22px;
    height: 22px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }
  
  .checkbox:checked + .checkbox-label .ball {
    transform: translateX(24px);
  }