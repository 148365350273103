.main-container {
    height: 100%;
  }

  .main-content {
    display: grid;
    min-height: 100%;
    place-items: center;
    background-color: white;
    padding: 6rem 1.5rem;
  }

  .main-content.sm {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .main-content.lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .text-section {
    text-align: center;
  }

  .error-code {
    font-size: 3rem;
    font-weight: 600;
    color: #6366f1;
  }

  .error-title {
    margin-top: 1rem;
    font-size: 1.875rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    color: #1f2937;
  }

  .error-title.sm {
    font-size: 3.125rem;
  }

  .error-description {
    margin-top: 1.5rem;
    font-size: 1rem;
    line-height: 1.75rem;
    color: #6b7280;
  }

  .button-container {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    text-decoration: none;
  }

  .home-button {
    border-radius: 0.25rem;
    background-color: #6366f1;
    padding: 0.625rem 0.875rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    text-decoration: none;
}

.home-button:hover {
      color: white;
    background-color: #4f46e5;
  }

  .home-button:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
    outline-color: #6366f1;
  }