/* styles.css */
.profile--container {
    display: flex;
    width: 100%;
  }
  .profile-card {
    margin-right: 20px;
  }
  
  .profile-card__container {
    width: 300px;
    margin-bottom: 20px;
  }
  .profile-card__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-card-head-title {
    font-size: 14px;
    font-weight: 700;
  }

  .form-component {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 680px;
    /* padding: 10px; */
  }
  .scrollable-list {
    max-height: 61vh;
    overflow-y: auto;
    margin-bottom: 20px;
  }
  
  .profile-card__avatar > img {
    object-fit: cover;
    border-radius: 10px !important;
  }
  
  .editConfigInputField,
  .editConfigSelectField {
    width: 290px;
  }
  /* HTML: <div class="loader"></div> */
  .loader {
    width: 25px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid lightblue;
    border-right-color: #444;
    animation: l2 1s infinite linear;
  }
  
  @keyframes l2 {
    to {
      transform: rotate(1turn);
    }
  }

  button:disabled {
    opacity: .2;
    cursor: not-allowed;
  }

  div.ant-upload{
    width: 100%;
  }
  
  @media only screen and (max-width: 780px) {
    .profile--container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .profile-card {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }
  
    .form-component {
      max-height: 100%;
    }
  }