@use "sass:map";

:root {
  --chat-box-bg-color: #ffffff; /* initial color for light theme */
  --chat-box-text-color: #171717; /* initial color for light theme */
  --secondary-color-light: #f9f9f9; /* secondary light color */
  --secondary-color-dark: #171717; /* secondary dark color */
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  font-family: map.get($theme-fonts, "primary-font");
  // background-color: var(--chat-box-bg-color) !important;
  color: var(--chat-box-text-color) !important;
  
}

h1 {
  color: $color-shark;
  font-size: 40px;
  line-height: 54px;
  font-weight: 700;
}

.text-decoration-none {
  text-decoration: none;
}

/* bi class missing from bootstrap icons */
.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.errorMsg {
  color: red;
}

/* adding custom scrollbar */

::-webkit-scrollbar {
  width: 14px !important;
  cursor: pointer !important;
}

::-webkit-scrollbar-track {
  // background-color: #F1F1F1 !important;
  background-color: var(--scrollbar-track);
  border-radius: 10px !important; 
}

::-webkit-scrollbar-thumb {
  // background-color: #b6b6b6 !important;
  background-color: var(--scrollbar-thumb);
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #A8A8A8 !important;
  cursor: pointer !important;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  background: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23676767' d='M12 8l-6 6h12z'/%3E%3C/svg%3E") no-repeat center !important;
  background-size: 14px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  cursor: pointer !important;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  background: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23676767' d='M12 16l6-6H6z'/%3E%3C/svg%3E") no-repeat center !important;
  background-size: 14px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  cursor: pointer !important;
}

::-webkit-scrollbar-button:single-button:vertical:decrement.dark-mode {
  background: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 8l-6 6h12z' fill='%23FFFFFF'/%3E%3C/svg%3E") no-repeat center;
  background-size: 14px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  cursor: pointer !important;
}


/* ----------------------------- */

.form-switch {
  position: relative;
}

.on-loading {
  .modal-dialog {
    width: 100vw;
    height: 100vh;
    .modal-content {
      position: absolute !important;
      width: 40px !important;
      background: none !important;
      top: 50% !important;
      left: 50% !important;
      border: 0;
    }
  }
}

.listing-table {
  th,
  td {
    text-align: center;
    min-width: 200px !important;
  }

  tr {
    height: 40px !important;
  }
}

/* listing action table width */
.table-action {
  width: 90px;
}

.toaster-position {
  position: absolute;
  right: 20px;
  top: 85px;
  border: 0;
  .toast-header {
    padding: 0;
    .info-container {
      padding: 10px 20px;
      text-align: center;
      background-color: #008000;
      .info-icon {
        color: #ffffff;
        font-size: 30px;
      }
    }
    .toaster-title {
      padding: 0 20px;
    }
  }
}

.hide {
  display: none;
}

.show {
  display: table-cell;
}

th {
  min-width: 180px !important;
  text-align: center;
}

td {
  text-align: center;
}

.table-column-filter {
  max-height: 110px;
  overflow: auto;
}

.table-column-btn {
  background-color: transparent;
  border: 0;
}

.table-column-btn:hover,
.table-column-btn:active {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.table-column-btn svg {
  color: #000000;
  font-size: 25px;
}

.table-column-btn::after {
  display: none;
}

// .show {
//   border: 0;
//   border-radius: 10px;
// }

.table-responsive {
  max-height: 60vh;
  margin-bottom: 20px;
}

.pagination {
  justify-content: center;
}

.toast-container > div {
  background-color: #ca1111;
}
.font-text-bold {
  font-weight: bold;
}
.react-datepicker-wrapper {
  width: auto !important;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

.offcanvas {
  width: 800px !important;
}

@media screen and (max-width: "1024px") {
  .offcanvas {
    width: 100% !important;
  }
}
// andt toast message styles
.ant-message-notice {
  display: flex;
  justify-content: flex-end;
}

a.btn.btn-outline-dark {
  border-color: var(--border-color);
  margin: 20px 20px 19px 0;
  color: var(--chat-box-text-color) !important;
}
