main {
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: var(--main-bg-color);
}

.sidebar {
  width: 280px;
  transition: all 0.5s ease-in;
}

.thread-sidebar {
  max-width: 16rem;
  width: 100%;
  background-color: var(--thread-bar-bg-2) !important;
  height: calc(100vh - 56.64px);
  border-right: 1px solid var(--border-color);
  z-index: -100;
  position: fixed;
  transform: translateX(-32rem);
  transition: all .8s ease;
  &.open-sidebar {
    transform: translateX(0rem);
  }
}

 .thread-search-wrapper {
 }

.thread-search {
  background-color: var(--chat-box-bg-color);
  color: var(--chat-box-text-color) !important;
  border: none;
  border-radius: 6px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-size: 13px !important;
  border: 1px solid var(--threadbar-border-color);
  box-shadow: var(--thead-box-shadow) 1px 2px 6px;
}
.thread-search:focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: var(--chat-box-bg-color);
  border: 1px solid var(--threadbar-border-color);
  box-shadow: var(--thead-box-shadow) 0px 1px 2px 0px;
}
.thread-search::placeholder {
  background-color: var(--chat-box-bg-color);
  color: gray !important;
  border: none;
}


.chat-history-wrapper {
  width: 100%;
  height: 80vh;
  overflow-y: auto; 
}

.main-chat-area-section {
  width: 100%;
  margin-left: 0;
  transition: all .8s ease;
  &.move-main-layout {
    margin-left: 16rem;
    width: calc(100% - 16rem);
  }
}
